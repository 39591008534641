import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { GREEN, RED, YELLOW } from "../utils/constants";
import moment from "moment";
import RefreshButton from "./RefreshButton";
import InfoInPopup from "./InfoInPopup";

const SpeedTestHistory = (props) => {
  const { setError, setLoading, loading } = props;
  const { device_uuid } = useParams();
  const [speedTestResults, setSpeedTestResults] = useState();
  const [tableData, setTableData] = useState([]);

  const getSpeedTestHistory = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/speed-test-history`)
      .then((response) => {
        if (response.status === 200) {
          setSpeedTestResults(response.data);
        }
      })
      .catch((error) => {
        setError({ text: error.response.data.detail });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSpeedTestHistory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tableDataToUse = [];

    if (speedTestResults && speedTestResults.cells) {
      for (const speedTestResult of speedTestResults.cells) {
        if (speedTestResult.str_text !== null) {
          const attributes = speedTestResult.str_text.split("\n");
          for (const attribute of attributes) {
            let splitter = "=";

            if (attribute.includes("ERROR")) {
              splitter = ":";
            }
            const attribute_array = attribute.split(splitter);
            speedTestResult[attribute_array[0].toLowerCase()] =
              attribute_array[1];
          }
        }

        tableDataToUse.push(speedTestResult);
      }
    }
    setTableData(tableDataToUse);
  }, [speedTestResults]);

  const columns = useRef([
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "str_type",
      headerName: "Test Type",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={
              params.row.str_type === 1
                ? "Normal"
                : params.row.str_type === 2
                ? "iPERF"
                : ""
            }
            size="small"
            color={
              params.row.str_type === 1
                ? "secondary"
                : params.row.str_type === 2
                ? "priamry"
                : ""
            }
          />
        );
      },
    },
    {
      field: "download_speed",
      headerName: "Download Speed (Mbps)",
      flex: 1,
    },
    {
      field: "upload_speed",
      headerName: "Upload Speed (Mbps)",
      flex: 1,
    },
    {
      field: "carrier",
      headerName: "Carrier",
      flex: 1,
    },
    {
      field: "pcc_band",
      headerName: "Primary Band & Strength",
      flex: 1,
      valueGetter: (params) => {
        return params.row.pcc_band && (`${params.row?.pcc_band} ${params.row?.pcc_strength}`);
      },
      minWidth: 200,
    },
    {
      field: "scc_band_info",
      headerName: "Secondary Band(s) & Signal Strength",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <React.Fragment>
          <Grid
            container
            alignContent="center"
            alignItems="top"
            spacing={0.5}
            style={{ lineHeight: 1.43 }}
          >
            {params.row.scc_band_info &&
              params.row.scc_band_info.map((band_info) => {
                return (
                  <>
                    <Grid item>
                      {band_info.band} &nbsp;&nbsp;&nbsp; {band_info.strength}
                    </Grid>
                   
                  </>
                );
              })}
          </Grid>
        </React.Fragment>
      ),
    },
    {
      field: "str_result",
      headerName: "Result",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={
              params.row.str_result === 2
                ? "SUCCESS"
                : params.row.str_result === 0
                ? "In Progress"
                : params.row.str_result === 3
                ? "Memory Error"
                : params.row.str_result === 4
                ? "Command Error"
                : params.row.str_result === 5
                ? "Dev Timeout Error"
                : params.row.str_result === 105
                ? "ISM Timeout Error"
                : params.row.str_result === 106
                ? "Net Mismatch"
                : params.row.str_result === 107
                ? "No Data"
                : ""
            }
            size="small"
            style={{
              fontWeight: 500,
              background:
                params.row.str_result === 2
                  ? GREEN
                  : params.row.str_result === 0
                  ? YELLOW
                  : RED,
            }}
          />
        );
      },
    },
    // NET_SPEED_PENDING			= 0,
    // (NET_SPEED_SUCCESS = 2),
    // (NET_SPEED_MEMORY_ERROR = 3),
    // (NET_SPEED_COMMAND_ERROR = 4),
    // (NET_SPEED_DEV_TIMEOUT_ERROR = 5),
    // (NET_SPEED_ISM_TIMEOUT_ERROR = 105),
    // (NET_SPEED_NET_MISMATCH = 106),
    // (NET_SPEED_NO_DATA = 107),
    {
      field: "str_time",
      headerName: "Last Updated At",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip
            title={moment(params.row.str_time * 1000)
              .local()
              .format("lll")}
          >
            <Typography>
              {moment(params.row.str_time * 1000)
                .local()
                .fromNow()}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "error",
      headerName: "Error",
      flex: 1,
      renderCell: (params) => {
        return <InfoInPopup text={params.row.error} />;
      },
    },
  ]);
  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <RefreshButton refreshAction={getSpeedTestHistory} />
          </Box>
        </Grid>
        <Grid item>
          <DataGrid
            rows={tableData}
            columns={columns.current}
            disableSelectionOnClick
            autoHeight
            rowCount={tableData.length}
            loading={loading}
            density="compact"
            rowHeight={100}
            components={{
              NoRowsOverlay: () => (
                <Box pt={20}>
                  <Grid
                    container
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography>No speed test results.</Typography>
                    </Grid>
                  </Grid>
                </Box>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
SpeedTestHistory.propTypes = {
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SpeedTestHistory;
