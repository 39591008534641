import axios from "axios";
import * as yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Layout from "../../layouts/Layout";

import { useFormik } from "formik";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const ManageSims = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [simGroups, setSimGroups] = useState([]);
  const [formData, setFormData] = useState([]);
  // 1 to assign to simGroup, 0 to unassign.
  const [assignOperation, setAssignOperation] = useState(1);

  const lineValidationSchema = yup.object({
    iccid: yup
      .string("iccid")
      .min(19, "Should be exactly 19 or 20 digits long")
      .max(20, "Should be exactly 19 or 20 digits long")
      .required(),
    imei: yup
      .string("imei")
      .test("Digits only", "The field should have 15 digits only", (value) =>
        value && value.length ? /^\d{15}$/.test(value) : true
      )
      .optional(),
    mdn: yup
      .string("mdn")
      .test("Digits only", "The field should have 10 digits only", (value) =>
        value && value.length ? /^\d{10}$/.test(value) : true
      )
      .optional(),
  });

  const validateFunction = (data) => {
    const errors = {};
    const formatedLines = [];

    if (!data) {
      return errors;
    }

    const lines = data.split("\n");
    lines.some((line, idx) => {
      const data = line.split(",");
      if (2 > data.length > 4) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      try {
        const line_data = line.split(",");
        const obtainedData =
          line_data.length === 1
            ? { iccid: line_data[0].trim() }
            : line_data.length === 2
            ? {
                iccid: line_data[0].trim(),
                imei: line_data[1].trim(),
              }
            : {
                iccid: line_data[0].trim(),
                imei: line_data[1].trim(),
                mdn: line_data[2].trim(),
              };

        lineValidationSchema.validateSync(obtainedData);

        if (isNaN(obtainedData.iccid)) {
          throw new Error("Invalid Number");
        }

        formatedLines.push(obtainedData);
      } catch (err) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      return false;
    });

    if (!errors.lines) {
      setFormData(formatedLines);
    }

    return errors;
  };

  const updateSims = () => {
    setLoading(true);
    axios
      .post(`/sim/bulk-update`, formData)
      .then(({ data }) => {
        const successMessages = [];
        const errorMessages = [];
        for (const simObject of data) {
          if (simObject.success) {
            successMessages.push(`${simObject.iccid} ${simObject.message}`);
          } else {
            errorMessages.push(`${simObject.iccid} ${simObject.message}`);
          }
        }
        if (successMessages.length !== 0) {
          setMessage(successMessages.join("\n"));
        }

        if (errorMessages.length !== 0) {
          setError(errorMessages.join("&lt;br /&gt;"));
        }
        // callOnSuccess();
      })
      .catch((error) => {
        setMessage({
          type: "error",
          text: error.response.data.detail,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      disable: "-1",
      validaityDate: null,
      simGroupId: null,
      unassignSimGroupId: null,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      for (const obtainedData of formData) {
        if (assignOperation) {
          obtainedData.sim_group_id = values.simGroupId;
          obtainedData.unassign_sim_group_id = null;
        } else {
          obtainedData.unassign_sim_group_id = values.unassignSimGroupId;
          obtainedData.sim_group_id = null;
        }
        obtainedData.disable = values.disable;
        obtainedData.validity_date =
          values.validaityDate !== null
            ? values.validaityDate.valueOf() / 1000
            : null;
      }

      //   alert(JSON.stringify(formData, null, 2));
      updateSims();
    },
  });

  const fetchSimGroups = () => {
    setLoading(true);
    setMessage(undefined);
    axios
      .get(`/sim/groups`)
      .then(({ data }) => {
        setSimGroups(data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSimGroups();
  }, []);

  useEffect(() => {
    if (assignOperation) {
      formik.setFieldValue("simGroupId", null);
    } else {
      formik.setFieldValue("unassignSimGroupId", null);
    }
  }, [assignOperation]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Layout
        loading={loading}
        error={error}
        successMessage={message}
        pageTitle={props.title}
        containerMaxWidth="md"
      >
        <Box pt={4}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Change SIM Status To</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="sim_status"
                    row
                    value={formik.values.disable}
                    onChange={(e) =>
                      formik.setFieldValue("disable", e.target.value || null)
                    }
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Activate"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Block"
                    />
                    <FormControlLabel
                      value="-1"
                      control={<Radio />}
                      label="None"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Set Validity Date</FormLabel>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline-from"
                    //   label="Set Validity Date"
                    value={formik.values.validaityDate}
                    onChange={(date) => {
                      formik.setFieldValue("validaityDate", date);
                      // setToDate(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "validity date",
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <RadioGroup
                  row
                  name="radio-assign-operation"
                  value={`${assignOperation}`}
                  onChange={(e) => setAssignOperation(parseInt(e.target.value))}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Assign"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Unassign"
                  />
                </RadioGroup>
                {assignOperation ? (
                  <FormControl style={{ minWidth: 180 }}>
                    <FormLabel component="legend">Assign Sim Group</FormLabel>
                    <Select
                      labelId="select-plan"
                      id="plan"
                      value={formik.values.simGroupId}
                      onChange={(e) =>
                        formik.setFieldValue("simGroupId", e.target.value)
                      }
                    >
                      {simGroups.map((plan, index) => (
                        <MenuItem value={plan.id} key={index}>
                          {plan.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl style={{ minWidth: 180 }}>
                    <FormLabel component="legend">Unassign Sim Group</FormLabel>
                    <Select
                      labelId="select-group-unassign"
                      id="group-unassign"
                      value={formik.values.unassignSimGroupId}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "unassignSimGroupId",
                          e.target.value
                        )
                      }
                    >
                      {simGroups.map((simGroup, index) => (
                        <MenuItem value={simGroup.id} key={index}>
                          {simGroup.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel htmlFor="lines">
                  {"Add in the format"}
                  <br></br>
                  {"ICCID,IMEI,MDN"}
                  <br></br>
                  {"ICCID,IMEI,MDN"}
                  <br></br>
                </InputLabel>
                <FormControl
                  error={Boolean(formik.errors.lines) ? true : false}
                  sx={{ width: "100%" }}
                >
                  <TextareaAutosize
                    multiline
                    rows={6}
                    aria-label="minimum height"
                    placeholder={`Add in the format\nICCID,IMEI,MDN\nICCID,IMEI,MDN`}
                    style={{ minWidth: 550 }}
                    name="lines"
                    minRows={5}
                    id="lines"
                    //   value={rawData}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const data = e.target.value;
                      //   setRawData(data);
                      formik.setErrors(validateFunction(data));
                    }}
                  />
                  {Boolean(formik.errors.lines) ? (
                    <FormHelperText id="lines-error-text">
                      {formik.errors.lines}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  // disabled={
                  //   formik.errors.lines ||
                  //   !linesData.length ||
                  //   (iqsimCallback && formik.values.sim_group_id === undefined)
                  // }
                  // onClick={() =>
                  //   !loading && !formik.isSubmitting && formik.submitForm()
                  // }
                  size="large"
                  loading={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {"Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Layout>
    </MuiPickersUtilsProvider>
  );
};

ManageSims.defaultProps = {
  title: "Manage Sim(s)",
};

export default ManageSims;
