import React from "react";
import moment from "moment";
import { Typography, Tooltip } from "@material-ui/core";

import { SignalCellularAlt } from "@material-ui/icons";

const getSignalColor = (signal) => {
  const value = parseInt(signal);
  if (value < -110) {
    return "red";
  } else if (value < -100) {
    return "orange";
  } else if (value < -90) {
    return "#00b300";
  } else {
    return "#00e600";
  }
};

const SignalStrength = ({ signal, cell_info }) => {
  if (!signal || signal === 'N/A') {
    return null;
  }
  const time = cell_info?.sci_time
    ? moment
        .utc(new Date(cell_info.sci_time * 1000))
        .local()
        .format("lll")
    : null;
  return (
    <Tooltip title={`${signal} Last Updated: ${time ? time : "N/A"}`}>
      <span
        style={{
          display: "inline-flex",
          alignItems: "flex-end",
        }}
      >
        {signal}
        <SignalCellularAlt style={{ color: getSignalColor(signal) }} />
      </span>
    </Tooltip>
  );
};

export default SignalStrength;
