export const parseSignal = (signal_data) => {
  if (!signal_data) return [];

  
  const signalPattern = /(RSRP|RSRQ|SINR):([-\d,]+),(LTE|NR5G)/g;
  const pccSccPattern = /"(PCC|SCC)".*?"([^"]+)"/g;

  // Extract matches for signal data
  const signalMatches = [];
  let match;
  while ((match = signalPattern.exec(signal_data)) !== null) {
    signalMatches.push({
      signal_type: match[1],
      signals: match[2].split(","),
      band_type: match[3],
    });
  }

  // Extract matches for PCC and SCC data
  const pccSccMatches = [];
  while ((match = pccSccPattern.exec(signal_data)) !== null) {
    pccSccMatches.push({
      signal_type: match[1],
      signals: [],
      band_type: match[2],
    });
  }

  // Combine both sets of results
  return [...signalMatches, ...pccSccMatches];
};

export const splitSignal = (signal) => (signal ? signal.split(";") : []);
