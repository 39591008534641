import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Layout from "../layouts/Layout";
import axios from "axios";
import CopyToClipboardDataGridHeader from "../components/CopyToClipboardDataGridHeader";
import CopyToClipboard from "../components/CopyToClipboard";
import { GetApp } from "@material-ui/icons";
import moment from "moment";

const AllSims = (props) => {
  const [loading, setLoading] = useState(false);
  const [sims, setSims] = useState([]);
  const [error, setError] = useState(undefined);
  const [rowsCount, setRowsCount] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = React.useState([
    { field: "used_data_formatted", sort: "desc" },
  ]);

  const [simsToSearch, setSimsToSearch] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  function getPostData() {
    let data = {
      filters: filterModel,
      sort: sortModel?.[0],
      simsToSearch: simsToSearch,
      simStatuses: [statusFilter],
    };

    return data;
  }

  const getSims = () => {
    setLoading(true);
    axios
      .post(
        "/sims",
        {
          ...getPostData(),
        },
        { params: { page, size: pageSize } }
      )
      .then(({ data }) => {
        setSims(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useRef([
    {
      field: "iccid",
      headerName: "ICCID",
      flex: 1,
      minWidth: 200,
      filterable: true,
      renderHeader: (params) => (
        <CopyToClipboardDataGridHeader params={params} prefix="'" />
      ),
    },
    {
      field: "sim_group",
      headerName: "Sim Groups",
      flex: 4,
      minWidth: 400,
      filterable: false,
      renderCell: (params) =>
        params.row.sim_group &&
        params.row.sim_group
          .split(", ")
          .map((sim_group) => (
            <Chip
              style={{ marginRight: "2px" }}
              label={sim_group}
              size="small"
              color="primary"
              key={`${params.row.id}-${params.row.sim_group}`}
            />
          )),
    },
    {
      field: "provider",
      headerName: "Provider",
      flex: 1,
      minWidth: 260,
      filterable: true,
    },
    {
      field: "devname",
      headerName: "GSM Device",
      filterable: true,
      minWidth: 250,
    },

    {
      field: "used_data_formatted",
      headerName: "Used Data (MB)",
      filterable: true,
      minWidth: 200,
    },
    {
      field: "validitydate_formatted",
      headerName: "Validity Date",
      filterable: true,
      minWidth: 250,
      type: "date",
    },
    {
      field: "slidnum",
      headerName: "SLID Num",
      flex: 1,
      minWidth: 260,
      filterable: true,
    },
    {
      field: "detail_status",
      headerName: "Status",
      minWidth: 250,
      filterable: true,
    },
    {
      field: "deactivdate_formatted",
      headerName: "Activ-Deactiv Date",
      filterable: true,
      minWidth: 250,
      type: "date",
    },
    {
      field: "rack_detail",
      headerName: "Sim Rack Position",
      filterable: false,
      flex: 1,
      minWidth: 350,
    },
  ]);

  const downloadExportData = () => {
    setLoading(true);
    axios.get(`/sims/export`).then(({ data }) => {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "text/csv" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `sparqfi_sims_export_${moment().format("YYYYMMDDhmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      getSims();
    }, 1000);
    // eslint-disable-next-line
  }, [page, filterModel, pageSize, sortModel, simsToSearch, statusFilter]);

  useEffect(() => {
    setLoading(true);
  }, []);
  const statusFilters = [
    { value: "", label: "All" },
    { value: "393216", label: "Active and Available" },
    { value: "65574", label: "Credit Validity Expired" },
    { value: "917540", label: "Enumerating" },
    { value: "65547", label: "Max Assignation" },
    { value: "196609", label: "Registered" },
    { value: "458752", label: "Removed" },
    { value: "720896", label: "Sleep" },
    { value: "65551", label: "To Be Activated" },
    { value: "65569", label: "Unreadable By Rack" },
  ];

  return (
    <Layout
      loading={loading}
      error={error}
      pageTitle={props.title}
      containerMaxWidth="xl"
    >
      <Box pt={3}>
        <Box pt={2}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={5}
          >
            <Grid item xs={3}>
              <TextField
                id="line_id"
                label="ICCID(s)"
                variant="outlined"
                fullWidth
                onChange={(e) => setSimsToSearch(e.target.value.split("\n"))}
                size="small"
                minRows={5}
                multiline
                maxRows={5}
              />
            </Grid>
            <Grid item>
              <Grid container alignItems="left" spacing={1} direction="column">
                <Grid item>
                  <Typography variant="h7" align="center">
                    Filter By Status :
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl style={{ minWidth: 180 }}>
                    <Select
                      displayEmpty
                      labelId="select-status"
                      id="status"
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                    >
                      {statusFilters.map((status, index) => (
                        <MenuItem value={status.value} key={index}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <CopyToClipboard
                    data={sims}
                    properties={[
                      "iccid",
                      "provider",
                      "devname",
                      "used_data_formatted",
                      "validitydate_formatted",
                      "slidnum",
                      "detail_status",
                      "deactivdate_formatted",
                      "rack_detail",
                    ]}
                    title="Copy All Sims"
                    size="large"
                  />
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadExportData}
                    startIcon={<GetApp />}
                  >
                    Export
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <React.Fragment>
          <Box pt={2}>
            <DataGrid
              rows={sims}
              columns={columns.current}
              disableSelectionOnClick
              autoHeight
              sortModel={sortModel}
              onPageChange={(newPage) => setPage(newPage + 1)}
              pageSize={pageSize}
              paginationMode="server"
              rowCount={rowsCount}
              initialState={{
                pagination: {
                  page: 1,
                },
              }}
              onPageSizeChange={setPageSize}
              filterMode="server"
              loading={loading}
              getRowId={(row) => row.id}
              onSortModelChange={(model) => setSortModel(model)}
              onFilterModelChange={(model) => {
                setFilterModel(model);
              }}
              density="compact"
            />
          </Box>
        </React.Fragment>
      </Box>
    </Layout>
  );
};

AllSims.defaultProps = {
  title: "All Sim(s)",
};

export default AllSims;
