import React from "react";
import moment from "moment";
import { Tooltip } from "@material-ui/core";

import { SignalCellularAlt } from "@material-ui/icons";
import SignalStrength from "./SignalStrength";
import { parseSignal } from "../utils/signalParser";

const AntennaSignalStrength = ({ device_type, cell_info }) => {
  const totalAntenna = device_type && device_type.includes("CAT20") ? 4 : 2;
  const signals = cell_info?.sci_ca_info;

  if (!signals) return null;

  const mapped_signals = parseSignal(signals).reduce((acc, signal) => {
    // only show RSRP signals
    if (signal.signal_type !== "RSRP") return acc;

    return {
      signals: signal?.signals.slice(0, totalAntenna),
      band: signal.band_type,
    };
  }, {});

  return (
    <>
      {mapped_signals.signals.map((value) => (
        <span style={{ marginRight: "10px" }} key={value}>
          <SignalStrength key={value} signal={value} cell_info={cell_info} />
        </span>
      ))}
    </>
  );
};

export default AntennaSignalStrength;
