import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import GoBackButton from "../components/GoBackButton";
import Layout from "../layouts/Layout";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableHeadCell from "../components/TableHeadCell";
import DeviceStatus from "../components/DeviceStatus";
import RefreshButton from "../components/RefreshButton";
import PortStatus from "../components/PortStatus";
import AddLabel from "../components/AddLabel";
import {
  getEmail,
  getName,
  hasActivateDeactivateAccess,
  hasNetworkingAccess,
  hasPlanChangeAccess,
  isSuperAdmin,
} from "../../src/auth/roles";
import ChangePortGroup from "../components/ChangePortGroup";
import SpeedTest from "../components/SpeedTest";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import SpeedTestHistory from "../components/SpeedTestHistory";
import { BLUE, SPEED_TEST_TYPES } from "../utils/constants";
import NetScanHistory from "../components/NetScanHistory";
import PermScanWifiIcon from "@material-ui/icons/PermScanWifi";
import NetDiagResult from "../components/NetDiagResult";
import StartNetDiag from "../components/StartNetDiag";
import { GREEN, RED, YELLOW } from "../utils/constants";
import ChangeDevicePlan from "../components/ChangeDevicePlan";
import PassStatus from "../components/PassStatus";
import moment from "moment";
import queryString from "query-string";
import { getTheme } from "../partners/utils";
import { Close, HelpOutline, Settings } from "@material-ui/icons";

import UpdateDeviceFW from "../components/UpdateDeviceFW";
import DeviceSsidModal from "./DeviceSsidModal";
import RestartDevice from "../components/RestartDevice";
import DeviceRestartScheduleModal from "./DeviceRestartScheduleModal";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import DeviceNetwork from "../components/DeviceNetwork";
import SignalStrength from "../components/SignalStrength";
import AntennaSignalStrength from "../components/AntennaStrength";
import { splitSignal } from "../utils/signalParser";

const styles = {
  chatWidget: {
    // Position
    position: "fixed",
    bottom: "20px",
    right: "20px",
  },
  formContainer: {
    // Position
    position: "fixed",
    bottom: "15px",
    right: "20px",
  },
  chatWidgetBtn: {
    color: "white",
    fontSize: "15px",
    textTransform: "capitalize",
    backgroundColor: getTheme().palette.primary.main,
    // Padding
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingTop: "7px",
    paddingBottom: "7px",
    // Border
    borderRadius: "50px",
    cursor: "pointer",
  },
};

const DeviceInfoSignal = (props) => {
  const { formtest } = queryString.parse(props.location.search);
  const { device_uuid } = useParams();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [m1Loading, setM1Loading] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(undefined);
  const [cellInfo, setCellInfo] = useState(undefined);
  const [m1Info, setM1Info] = useState(undefined);
  const [deviceDBInfo, setDeviceDBInfo] = useState(undefined);
  const [portInfo, setPortInfo] = useState(undefined);
  const [latestSpeedTestResult, setLatestSpeedTestResult] = useState(undefined);
  const [latestNetScanResult, setLatestNetScanResult] = useState(undefined);
  const [tabValue, setTabValue] = useState("Info");
  const [message, setMessage] = useState(undefined);
  const [ssidModal, setSsidModal] = useState(false);
  const [confirmConfigDialog, setConfirmConfigDialog] = useState(false);

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const getDeviceInfo = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/info`)
      .then((response) => {
        setDeviceInfo(response.data.device);
        setPortInfo(response.data.port);
        setDeviceDBInfo(response.data.db_info);
        setCellInfo(response.data.cell_info);
        setLatestSpeedTestResult(response.data.latest_speed_test_result);
        setLatestNetScanResult(response.data.latest_net_scan_result);
      })
      .catch((error) => {
        setError(error?.response?.data?.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getM1Info = () => {
    setM1Loading(true);
    axios
      .get(`/device/${device_uuid}/m1_info`)
      .then((response) => {
        setM1Info(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.detail);
      })
      .finally(() => {
        setM1Loading(false);
      });
  };

  const initiateNetScan = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/start-net-scan`)
      .then((response) => {
        if (response.status === 200) {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setDefaultDeviceConfig = () => {
    setLoading(true);
    axios
      .post(`/device/${device_uuid}/default_config`)
      .then((response) => {
        setConfirmConfigDialog(null);
        if (response.data.success) {
          setMessage(response.data.message);
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const activateM1Iccid = () => {
    setLoading(true);
    axios
      .post(`/device/${device_uuid}/activate_m1_iccid`)
      .then((response) => {
        if (response.data.success) {
          setMessage(response.data.message);
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   if (deviceInfo?.serial !== undefined && portInfo !== undefined) {
  //     console.log(deviceInfo?.serial);
  //     window.FreshworksWidget("identify", "ticketForm", {
  //       name: getName(),
  //       email: getEmail(),
  //     });

  //     window.FreshworksWidget("hide", "ticketForm", [
  //       "subject",
  //       "name",
  //       "email",
  //       "product_id",
  //       "custom_fields.cf_device_label",
  //       "custom_fields.cf_port_group",
  //       "custom_fields.cf_device_m1_status",
  //       "custom_fields.cf_used_data_mb",
  //       "custom_fields.cf_software_version",
  //       "custom_fields.cf_admin_link",
  //       "custom_fields.cf_hostnamepublic_ip",
  //       "custom_fields.cf_gps_coordinates",
  //       "custom_fields.cf_sim_m2_provider",
  //       "custom_fields.cf_band",
  //       "custom_fields.cf_sim_m2_status",
  //       "custom_fields.cf_signal_strength106409",
  //       "custom_fields.cf_tower_connection_info",
  //     ]);
  //     console.log(`Test : ${portInfo?.spt_band}`);

  //     window.FreshworksWidget("prefill", "ticketForm", {
  //       subject: `Issue with device ${deviceInfo.serial}`,
  //       custom_fields: {
  //         cf_device_serial_no: deviceInfo.serial, //Single line text field
  //         cf_device_label:
  //           deviceInfo?.label === null ? "NO LABEL" : deviceInfo?.label, //Single line text field
  //         cf_port_group: deviceInfo.portgroups, //Single line text field
  //         cf_device_m1_status:
  //           deviceInfo?.status_formatted === ""
  //             ? "NO STATUS"
  //             : deviceInfo?.status_formatted,
  //         cf_used_data_mb: deviceInfo?.useddata
  //           ? (deviceInfo.useddata / 1024).toFixed(2)
  //           : "",
  //         cf_software_version: deviceInfo?.app_version,
  //         cf_admin_link: deviceInfo?.admin_link,
  //         cf_hostnamepublic_ip: deviceInfo?.ip,
  //         cf_gps_coordinates: deviceInfo?.gps,
  //         cf_sim_m2_provider: portInfo?.sim_provider,
  //         cf_band: portInfo?.spt_band,
  //         cf_sim_m2_status:
  //           portInfo?.status_formatted === ""
  //             ? "NO STATUS"
  //             : portInfo?.status_formatted,
  //         cf_signal_strength106409: portInfo?.spt_signal,
  //         cf_tower_connection_info: `MCC - ${portInfo?.mcc} , MNC - ${portInfo?.mnc} , LAC - ${portInfo?.spt_lac} , Cell Id - ${portInfo?.spt_cellid}`,
  //       },
  //     });

  //     window.FreshworksWidget("disable", "ticketForm", [
  //       "custom_fields.cf_device_serial_no",
  //     ]);
  //   }
  // }, [deviceInfo, portInfo]);

  useEffect(() => {
    getDeviceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      window.FreshworksWidget("destroy");
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getM1Info();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      window.FreshworksWidget("destroy");
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const showNetworking = () =>
    (isSuperAdmin() || hasNetworkingAccess()) && deviceInfo?.serial;

  const ConfirmDefaultDialog = (_) => {
    return (
      <Dialog
        open={confirmConfigDialog}
        onClose={() => setConfirmConfigDialog(null)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to reset the device to default configuration?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={loading}
            onClick={setDefaultDeviceConfig}
            color="primary"
          >
            Confirm
          </Button>
          <Button onClick={() => setConfirmConfigDialog(null)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Layout
      containerMaxWidth="xl"
      loading={loading}
      error={error}
      successMessage={message}
    >
      {/* <Helmet>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/151000003887.js"
          async
          defer
        ></script>
        ;
      </Helmet> */}
      <ConfirmDefaultDialog />
      <Box pt={2} pb={2}>
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <GoBackButton />
              </Grid>
              {deviceInfo && (
                <Grid item>
                  <Typography>{`Serial : ${deviceInfo?.serial}`}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {ssidModal && (
            <DeviceSsidModal
              handleClose={setSsidModal}
              device_uuid={device_uuid}
              open={ssidModal}
            />
          )}
          <Grid item>
            <Grid container spacing={5}>
              <Grid item>
                <SpeedTest
                  setLoading={setLoading}
                  name="Rapid"
                  speed_test_type={SPEED_TEST_TYPES.IPERF}
                />
              </Grid>
              <Grid item>
                <SpeedTest
                  setLoading={setLoading}
                  name="Normal"
                  speed_test_type={SPEED_TEST_TYPES.NORMAL}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={initiateNetScan}
                  color="primary"
                  variant="outlined"
                  startIcon={<PermScanWifiIcon />}
                >
                  Start Net Scan
                </Button>
              </Grid>
              <Grid item>
                <StartNetDiag setLoading={setLoading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <TabContext value={tabValue}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Device Info" value="Info" />
            <Tab label="Speed Test History" value="SpeedTest" />
            <Tab label="Net Scan History" value="NetScan" />
            <Tab label="Net Diagnosis Result" value="NetDiag" />
            <Tab
              label={showNetworking() && deviceInfo ? "Networking" : ""}
              value="Networking"
            />
          </TabList>
        </AppBar>
        <TabPanel value="Info">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Box display="flex" justifyContent="flex-end">
                {hasActivateDeactivateAccess() &&
                  !loading &&
                  deviceInfo?.status_formatted === "Reachable" && (
                    <>
                      <DeviceRestartScheduleModal
                        deviceUUID={device_uuid}
                        initialData={{
                          scheduled_restart_frequency:
                            deviceDBInfo?.scheduled_restart_frequency || null,
                          scheduled_restart_time:
                            deviceDBInfo?.scheduled_restart_time || null,
                          scheduled_restart_day:
                            deviceDBInfo?.scheduled_restart_day ?? null,
                        }}
                        setMessage={setMessage}
                        setError={setError}
                      />
                      <RestartDevice
                        deviceUUID={device_uuid}
                        deviceData={deviceInfo}
                        setMessage={setMessage}
                        setError={setError}
                      />
                    </>
                  )}
                {portInfo?.status_formatted === "Registered" && (
                  <Button
                    onClick={() => setSsidModal(true)}
                    color="primary"
                    variant="contained"
                    startIcon={<PermScanWifiIcon />}
                    style={{ marginRight: "12px" }}
                  >
                    Update SSID
                  </Button>
                )}
                <Tooltip
                  title={deviceInfo?.changes_pending ? "Changes pending" : ""}
                >
                  <span>
                    <Button
                      onClick={() => setConfirmConfigDialog(true)}
                      color="primary"
                      variant="contained"
                      disabled={deviceInfo?.changes_pending}
                      startIcon={<Settings />}
                      style={{ marginRight: "12px" }}
                    >
                      Reset Device Config
                    </Button>
                  </span>
                </Tooltip>
                <RefreshButton refreshAction={getDeviceInfo} />
              </Box>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table aria-label="profile table">
                  <TableBody>
                    <TableRow>
                      <TableHeadCell>Label</TableHeadCell>
                      <TableCell>
                        {deviceInfo && (
                          <AddLabel
                            serial_no={deviceInfo?.serial}
                            device_id={device_uuid}
                            label={deviceInfo?.label}
                            setLoading={setLoading}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeadCell>Serial</TableHeadCell>
                      <TableCell>
                        <Grid container spacing={2}>
                          <Grid item>{deviceInfo?.serial}</Grid>
                          {deviceInfo?.device_type && (
                            <Grid item>
                              <Chip
                                color="primary"
                                size="small"
                                label={deviceInfo?.device_type}
                              ></Chip>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableHeadCell>
                        Port Group (Preferred Carrier)
                      </TableHeadCell>
                      <TableCell>
                        <ChangePortGroup
                          port_group={deviceInfo?.portgroups}
                          device_uuid={device_uuid}
                          device_name={deviceInfo?.name}
                          setLoading={setLoading}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableHeadCell>Used data (MB)</TableHeadCell>
                      <TableCell>
                        {deviceInfo?.useddata &&
                          (deviceInfo.useddata / 1024).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeadCell>Device Status</TableHeadCell>
                      <TableCell>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <DeviceStatus
                              status={deviceInfo?.status}
                              status_formatted={deviceInfo?.status_formatted}
                            />
                          </Grid>
                          {deviceInfo?.sleeping === 1 && (
                            <Grid item>
                              <Tooltip title="Device is in sleep mode">
                                <NightsStayIcon color="primary" />
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeadCell>Device Plan</TableHeadCell>
                      <TableCell>
                        {hasPlanChangeAccess() ? (
                          <ChangeDevicePlan
                            device_plan={deviceInfo?.deviceplan}
                            device_uuid={device_uuid}
                            device_name={deviceInfo?.name}
                            setLoading={setLoading}
                          />
                        ) : (
                          <Typography>{deviceInfo?.deviceplan}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeadCell>Pass Status</TableHeadCell>
                      <TableCell>
                        <PassStatus
                          status={deviceInfo?.pass_status}
                          status_formatted={deviceInfo?.pass_status_formatted}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeadCell>Sim Status</TableHeadCell>
                      <TableCell>
                        <Grid container spacing={2} direction="row">
                          <Grid item>
                            <PortStatus
                              status={portInfo?.status}
                              status_formatted={portInfo?.status_formatted}
                              setLoading={setLoading}
                              setMessage={setMessage}
                              setError={setError}
                            />
                          </Grid>
                          {portInfo?.is_manual_allocation && (
                            <Grid item>
                              <Chip
                                label={"Manual Allocation"}
                                size="small"
                                style={{
                                  fontWeight: 500,
                                  background: BLUE,
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeadCell>Sim Provider</TableHeadCell>
                      <TableCell>{portInfo?.sim_provider}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableHeadCell>
                        Primary Band & Signal Strength
                      </TableHeadCell>
                      <TableCell>
                        {portInfo?.pcc_band || "Band " + cellInfo?.sci_band}{" "}
                        &nbsp;&nbsp;&nbsp;{" "}
                        <SignalStrength
                          signal={
                            cellInfo?.sci_signal
                              ? cellInfo?.sci_signal * -1
                              : portInfo?.pcc_strength
                          }
                          cell_info={cellInfo}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableHeadCell>
                        Secondary Band(s) & Signal Strength
                      </TableHeadCell>
                      <TableCell>
                        {portInfo?.scc_band_info &&
                          portInfo?.scc_band_info.map((band_info) => {
                            return (
                              <div>
                                {band_info.band} &nbsp;&nbsp;&nbsp;{" "}
                                <SignalStrength
                                  signal={
                                    cellInfo && band_info?.band.includes("5G")
                                      ? cellInfo.sci_endc_signal * -1
                                      : band_info?.strength
                                  }
                                  cell_info={cellInfo}
                                />
                              </div>
                            );
                          })}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableHeadCell>Tower Connection Info</TableHeadCell>
                      <TableCell>
                        <Grid container>
                          <Grid item>
                            {` MCC - ${portInfo?.mcc} , MNC - ${portInfo?.mnc} , LAC - ${portInfo?.spt_lac} , Cell Id - ${portInfo?.spt_cellid}`}
                            <div>
                              <Tooltip title="Carrier Agg Info">
                                <Typography variant="caption">
                                  {splitSignal(
                                    cellInfo?.sci_ca_info ||
                                      portInfo?.spt_ca_info
                                  ).map((signal) => (
                                    <div key={signal}>{signal}</div>
                                  ))}
                                </Typography>
                              </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeadCell>Antenna Signal Strength</TableHeadCell>
                      <TableCell>
                        <AntennaSignalStrength
                          device_type={deviceInfo?.device_type}
                          cell_info={cellInfo}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableHeadCell>Software Version</TableHeadCell>
                      <TableCell>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>{deviceInfo?.app_version}</Grid>
                          <Grid item>
                            {deviceInfo?.is_fw_update_available &&
                              deviceInfo?.status === 1 && (
                                <UpdateDeviceFW
                                  deviceData={{
                                    ...deviceInfo,
                                    uuid: device_uuid,
                                  }}
                                  setMessage={setMessage}
                                  setError={setError}
                                />
                              )}
                          </Grid>
                          <Grid item>
                            {deviceInfo?.is_fw_update_in_progress && (
                              <Chip
                                label="Firmware Update in Progress"
                                color="primary"
                                size="small"
                              />
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                    {deviceInfo && (
                      <TableRow>
                        <TableHeadCell>Admin Link</TableHeadCell>
                        <TableCell>
                          <Link
                            href={deviceInfo?.admin_link}
                            variant="body2"
                            target="_blank"
                          >
                            {deviceInfo?.admin_link}
                          </Link>
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableHeadCell>GPS Co-Ordinates</TableHeadCell>
                      <TableCell>
                        <Tooltip title="Open in Google Maps">
                          <Link
                            target="_blank"
                            href={`https://maps.google.com/?q=${deviceInfo?.gps}`}
                          >
                            {deviceInfo?.gps}
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>

                    {!m1Loading && m1Info && Object.keys(m1Info).length > 0 && (
                      <TableRow>
                        <TableHeadCell>{`M1${
                          m1Info.iccid ? " ICCID & " : " "
                        }Status`}</TableHeadCell>
                        <TableCell>
                          <Grid container spacing={3}>
                            {m1Info.iccid && (
                              <Grid item>
                                <Typography>{m1Info.iccid}</Typography>
                              </Grid>
                            )}

                            <Grid item>
                              <Chip
                                label={m1Info.status}
                                size="small"
                                style={{
                                  fontWeight: 500,
                                  background:
                                    m1Info.status === "activated"
                                      ? GREEN
                                      : m1Info.status === "deactivated"
                                      ? RED
                                      : YELLOW,
                                  color: m1Info.status === "deactivated",
                                }}
                              />
                            </Grid>

                            {m1Info.tags &&
                              m1Info.tags.map((tag) => {
                                return (
                                  <Grid item>
                                    <Chip
                                      label={tag}
                                      size="small"
                                      style={{
                                        fontWeight: 500,
                                        background: BLUE,
                                      }}
                                    />
                                  </Grid>
                                );
                              })}
                            {m1Info.roaming_profile && (
                              <Grid item>
                                <Tooltip title="Roaming Profile">
                                  <Chip
                                    label={m1Info.roaming_profile}
                                    size="small"
                                    color="primary"
                                  />
                                </Tooltip>
                              </Grid>
                            )}
                            {m1Info?.status !== "activated" &&
                              (!(
                                m1Info.tags &&
                                m1Info.tags.includes("imei_change")
                              ) ||
                                isSuperAdmin()) && (
                                <Grid item>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={activateM1Iccid}
                                  >
                                    Acitvate M1
                                  </Button>
                                </Grid>
                              )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}

                    {portInfo && portInfo?.sim_iccid && (
                      <TableRow>
                        <TableHeadCell>M2 Sim ICCID</TableHeadCell>
                        <TableCell>
                          <Grid container spacing={3}>
                            {portInfo?.sim_iccid && (
                              <Grid item>
                                <Typography>{portInfo?.sim_iccid}</Typography>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableHeadCell>Latest Speed Test Result</TableHeadCell>
                      <TableCell>
                        {latestSpeedTestResult &&
                          latestSpeedTestResult.download_speed && (
                            <Typography>
                              {`${latestSpeedTestResult.download_speed} down ${
                                latestSpeedTestResult.upload_speed
                              } up  ${latestSpeedTestResult.carrier} ${
                                latestSpeedTestResult.pcc_band
                              } Last Updated ${moment(
                                latestSpeedTestResult.str_time * 1000
                              )
                                .local()
                                .fromNow()}`}
                            </Typography>
                          )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeadCell>
                        Best Signal From Last Net Scan
                      </TableHeadCell>
                      <TableCell>
                        {latestNetScanResult &&
                          latestNetScanResult.best_result && (
                            <Typography>
                              {`${
                                latestNetScanResult?.best_result.operator_name
                              } 
                          ${latestNetScanResult?.best_result.rat} BAND  
                          ${latestNetScanResult?.best_result.band} 
                          ${latestNetScanResult?.best_result.signal} (dBm) 
                          Last Updated ${moment(
                            latestNetScanResult?.nsr_time * 1000
                          )
                            .local()
                            .fromNow()} `}
                            </Typography>
                          )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="SpeedTest">
          <SpeedTestHistory
            setError={setError}
            setLoading={setLoading}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value="NetScan">
          <NetScanHistory
            setError={setError}
            setLoading={setLoading}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value="NetDiag">
          <NetDiagResult
            setError={setError}
            setLoading={setLoading}
            loading={loading}
          />
        </TabPanel>
        {(isSuperAdmin() || hasNetworkingAccess()) && deviceInfo?.serial && (
          <TabPanel value="Networking">
            <DeviceNetwork
              setError={setError}
              setMessage={setMessage}
              deviceSerial={deviceInfo.serial}
            />
          </TabPanel>
        )}
      </TabContext>

      <div>
        <div
          style={{
            ...styles.chatWidget,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={styles.chatWidgetBtn}
              startIcon={<HelpOutline />}
              onClick={() => setIsHelpFormOpen(true)}
            >
              Help
            </Button>
          </div>
        </div>
        {isHelpFormOpen && (
          <div style={styles.formContainer}>
            <Grid container direction="column" justifyContent="right">
              <Grid item>
                <Button
                  style={{
                    position: "fixed",
                    right: "20px",
                  }}
                  startIcon={<Close />}
                  onClick={() => setIsHelpFormOpen(false)}
                >
                  Close
                </Button>
              </Grid>
              <Grid item>
                <iframe
                  src={`https://share.hsforms.com/147xdoeuFSpWSz7KOB6pBYArg7tl?firstname=${getName()}&lastname=${getName()}&email=${getEmail()}&serial_number=${
                    deviceInfo?.serial
                  }&port_group=${deviceInfo?.portgroups}&used_data=${
                    deviceInfo?.useddata
                  }&subject=${`Device ID ${deviceInfo?.serial}`}&pass_status=${
                    deviceInfo?.pass_status_formatted
                  }&device_status=${
                    deviceInfo?.status_formatted
                  }&sim_provider=${portInfo?.sim_provider}&sim_status=${
                    portInfo?.status_formatted
                  }&software_version=${deviceInfo?.app_version}&signal=${
                    portInfo?.spt_signal
                  }`}
                  height="770px"
                  width="500px"
                ></iframe>
              </Grid>
            </Grid>
          </div>
        )}
      </div>

      {/* {formtest && (
        <>
          <Typography>
            {`https://share.hsforms.com/147xdoeuFSpWSz7KOB6pBYArg7tl?firstname=${getName()}&
            lastname=${getName()}
            &email=${getEmail()}
            &serial_number=${deviceInfo?.serial}&subject=${
              deviceInfo?.serial
            }&port_group=${deviceInfo?.portgroups}&used_data=${
              deviceInfo?.useddata
            }&pass_status=${deviceInfo?.pass_status_formatted}&device_status=${
              deviceInfo?.status_formatted
            }&sim_provider=${portInfo?.sim_provider}&sim_status=${
              portInfo?.status_formatted
            }&software_version=${deviceInfo?.app_version}&signal=${
              portInfo?.spt_signal
            }`}
          </Typography>
        </>
      )} */}
    </Layout>
  );
};

export default DeviceInfoSignal;
