import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Chip,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import ChangePortGroup from "../components/ChangePortGroup";
import DateAndTime from "../components/DateAndTime";
import Layout from "../layouts/Layout";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { blue, green } from "@material-ui/core/colors";
import ActivateDevice from "../components/ActivateDevice";
import { GetApp } from "@material-ui/icons";
import DeviceActionHistory from "../components/DeviceActionHistory";
import DeviceStatus from "../components/DeviceStatus";
import AddLabel from "../components/AddLabel";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import InfoIcon from "@material-ui/icons/Info";
import { hasActivateDeactivateAccess } from "../auth/roles";
import DeactivateDevice from "../components/DeactivateDevice";

const Devices = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [deviceId, setDeviceId] = useState("");
  const [activateDeviceData, setActivateDeviceData] = useState(undefined);
  const [rowsCount, setRowsCount] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [filterModel, setFilterModel] = useState();
  const [sortModel, setSortModel] = React.useState([
    { field: "used_data", sort: "desc" },
  ]);

  const isSearchMode = () => {
    return deviceId !== undefined && deviceId.trim() !== "";
  };

  const getParams = () => {
    let params = { filters: filterModel, sort: sortModel?.[0] };
    if (isSearchMode()) {
      params.q = deviceId;
    }
    return params;
  };

  const getDevices = () => {
    setLoading(true);
    axios
      .post(
        "/devices",
        {
          ...getParams(deviceId),
        },
        { params: { page, size: pageSize } }
      )
      .then(({ data }) => {
        setDeviceData(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearSearch = () => {
    setDeviceId("");
    getDevices();
  };

  const columns = useRef([
    {
      field: "serial_no",
      headerName: "Serial No.",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Grid
            container
            alignContent="center"
            alignItems="top"
            spacing={1}
            style={{ lineHeight: 1.43 }}
          >
            <Grid item>
              <Grid container alignItems="left" direction="column" spacing={0}>
                <Grid item>
                  <Typography>{params.row.serial_no}</Typography>
                </Grid>
                {params.row.device_type && (
                  <Grid item>
                    <Chip
                      color="primary"
                      size="small"
                      label={params.row.device_type}
                    ></Chip>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item>
              <DeviceActionHistory
                device_id={params.row.uuid}
                serial_no={params.row.serial_no}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "mac_address",
      headerName: "MAC Address",
      flex: 1,
      minWidth: 260,
      filterable: true,
    },
    {
      field: "label",
      headerName: "Label",
      flex: 1,
      minWidth: 240,
      filterable: true,
      valueGetter: (params) => params.row.label,
      renderCell: (params) => {
        return (
          <AddLabel
            serial_no={params.row.serial_no}
            device_id={params.row.uuid}
            label={params.row.label}
            setLoading={setLoading}
          />
        );
      },
    },
    {
      field: "port_group",
      headerName: "Port Groups",
      flex: 1,
      minWidth: 240,
      filterable: true,
      renderCell: (params) => {
        return (
          <ChangePortGroup
            port_group={params.row.port_group}
            device_uuid={params.row.uuid}
            device_name={params.row.name}
            setLoading={setLoading}
          />
        );
      },
    },

    {
      field: "status_formatted",
      headerName: "Status",
      minWidth: 150,
      filterable: true,
      renderCell: (params) => (
        <DeviceStatus
          status={params.row.status}
          status_formatted={params.row.status_formatted}
        />
      ),
    },
    {
      field: "used_data",
      headerName: "Used Data (MB)",
      filterable: true,
      minWidth: 160,
      renderCell: (params) => (
        <Typography>{params.row.used_data_formatted}</Typography>
      ),
    },
    {
      field: "reseller_id",
      headerName: "Reseller",
      filterable: true,
      minWidth: 150,
      renderCell: (params) => (
        <Typography>{params.row.reseller_name}</Typography>
      ),
    },
    {
      field: "last_updated_at",
      headerName: "Last Updated At",
      filterable: false,
      minWidth: 160,
      valueGetter: (params) =>
        params.row.last_updated_at &&
        parseInt(moment(params.row.last_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_updated_at && (
          <DateAndTime dateAndTime={params.row.last_updated_at} />
        ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Grid container justifyContent="flex-start" alignItems="center">
              {hasActivateDeactivateAccess() && !params.row.is_activated && (
                <Grid item>
                  <Tooltip title="Activate Device">
                    <IconButton
                      onClick={() => {
                        setActivateDeviceData(params.row);
                      }}
                      size="small"
                    >
                      <PlayArrowIcon
                        style={{
                          color: green[500],
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {hasActivateDeactivateAccess() && params.row.is_activated && (
                <DeactivateDevice
                  deviceData={params.row}
                  setMessage={setMessage}
                  setError={setError}
                />
              )}

              <Grid item>
                <Tooltip title="Device Details">
                  <IconButton
                    onClick={() => {
                      history.push(`/device/${params.row.uuid}/info`);
                    }}
                    size="small"
                  >
                    <InfoIcon
                      style={{
                        color: blue[500],
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      },
    },
  ]);

  const downloadExportData = () => {
    setLoading(true);
    axios.get(`/devices/export`).then(({ data }) => {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "text/csv" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `sparqfi_device_export_${moment().format("YYYYMMDDhmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });
  };

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line
  }, [page, filterModel, pageSize, sortModel]);

  return (
    <Layout
      loading={loading}
      error={error}
      successMessage={message}
      title={props.title}
      containerMaxWidth="xl"
    >
      <Box pt={3}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={12} md={8} lg={8}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={4}
              alignItems="center"
            >
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  id="device_id"
                  label="Search Device by name, mac, serial no, m1 iccid or label"
                  variant="outlined"
                  fullWidth
                  value={deviceId}
                  onChange={(e) => setDeviceId(e.target.value)}
                  size="small"
                  disableAutoFocus
                  multiline
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => getDevices(deviceId)}
                >
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={clearSearch}
                  disabled={deviceId === undefined || deviceId.trim() === ""}
                >
                  Clear Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadExportData}
              startIcon={<GetApp />}
            >
              Export
            </Button>
          </Grid>
        </Grid>

        <React.Fragment>
          <Box pt={2}>
            <DataGrid
              rows={deviceData}
              columns={columns.current}
              disableSelectionOnClick
              autoHeight
              rowHeight={100}
              sortModel={sortModel}
              onPageChange={(newPage) => setPage(newPage + 1)}
              pageSize={pageSize}
              paginationMode="server"
              rowCount={rowsCount}
              initialState={{
                pagination: {
                  page: 1,
                },
              }}
              onPageSizeChange={setPageSize}
              filterMode="server"
              loading={loading}
              getRowId={(row) => row.uuid}
              onSortModelChange={(model) => setSortModel(model)}
              onFilterModelChange={(model) => {
                setFilterModel(model);
              }}
              density="compact"
            />
          </Box>
        </React.Fragment>

        {/* <ChangePortGroup
          open={changePortGroupDeviceData !== undefined}
          onClose={() => {
            setChangePortGroupDeviceData(undefined);
          }}
          deviceData={changePortGroupDeviceData}
        ></ChangePortGroup> */}

        <ActivateDevice
          open={activateDeviceData !== undefined}
          onClose={() => {
            setActivateDeviceData(undefined);
          }}
          deviceData={activateDeviceData}
        ></ActivateDevice>
      </Box>
    </Layout>
  );
};

Devices.defaultProps = {
  title: "Manage Devices",
};

export default Devices;
